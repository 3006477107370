/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from "react"
import { useRouter } from "next/router"
import * as Sentry from "@sentry/react"
import "react-multi-carousel/lib/styles.css"
import { GetStaticPropsContext } from "next"
import { Content } from "@prismicio/client"
import { RichTextField } from "@prismicio/types"
import { WidgetProps } from "components"
import { LoginPageType, createTN2Client, SliceType, getValidationRulesAndMessages, getRiskWarnings } from "utils"

import { useSignupData } from "hooks"
import { createClient } from "../../prismicio"
import Login from "components/pages/login/Login"

export type LoginPageProps = {
  page: LoginPageType
  widgetprops?: WidgetProps
  // translation?: {
  //   pageTranslation: Content.TranslationDocument
  //   validationMessagesTranslation: Content.TranslationDocument
  //   welcomePageLeftSideTranslation: Content.TranslationDocument
  // }
  validation: {
    messages: Content.ValidationmessagesDocument
  }
}

export type TranslationsDataType = {
  title: RichTextField
  titleOrangeText: string
  description?: RichTextField
  slices: SliceType[]
  linkTextToSignup: RichTextField
  wordSeparator?: string
}

const LoginPage = ({ page, widgetprops, validation }: LoginPageProps) => {
  // Reset password
  const { updatePromptMessage } = useSignupData()
  const router = useRouter()
  const { pathname, query: urlParams } = router

  useEffect(() => {
    if (urlParams.message && urlParams.success) {
      updatePromptMessage({
        message: urlParams.message as string,
        success: urlParams.success === "true"
      })

      // Remove params from the url
      const params = new URLSearchParams(window.location.search)
      params.delete("message")
      params.delete("success")
      router.replace({ pathname, query: params.toString() }, undefined, {
        shallow: true
      })
    }
  }, [urlParams])

  return <Login page={page} widgetprops={widgetprops} validation={validation} />
}

export default LoginPage

export const getStaticProps = async ({ previewData }: GetStaticPropsContext) => {
  try {
    const client = createClient({ previewData })
    const page = await client.getByUID("login", "login")
    const clientTN2 = createTN2Client()
    const riskWarnings = await getRiskWarnings(clientTN2)
    const { validation } = await getValidationRulesAndMessages(client)
    return {
      props: { page, validation, riskWarnings, category: "login" },
      revalidate: 60
    }
  } catch (error) {
    Sentry.captureException(error)
    return {
      notFound: true,
      revalidate: 60
    }
  }
}
