import styled from "@emotion/styled"
import { Box, Container } from "@mui/material"
import { darkBlueColor, kanit, mainColor, orangeColor, theme } from "styles"
import Image from "next/image"

const CustomBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "widget" && prop !== "isMobile"
})<{ widget?: boolean; isMobile?: boolean }>``

export const DesktopContainer = styled(CustomBox)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: ${darkBlueColor};
  padding: ${({ isMobile }) => (isMobile ? "20px 0 55px" : "55px 0px")};
  justify-content: center;
  background: ${(props) => props.widget && "linear-gradient(45deg, #09111c 75%, #214866 100%)"};

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: ${mainColor};
    z-index: 2;
  }
`

export const Subtitle = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
`

export const DesktopContent = styled(Box)<{
  widget?: boolean
  isMobile?: boolean
}>`
  width: ${(props) => (props.widget ? "1400px" : "100%")};
  border: ${(props) => props.widget && "2px solid #5f6b71"};
  border-radius: ${(props) => (props.widget && props.isMobile ? "20px" : "40px")};
`

export const LogoAreaStyle = (useDesktopXLMode: boolean, disclaimerHeight: number, isMobile?: boolean) => ({
  top: useDesktopXLMode ? 6 : 10 + disclaimerHeight,
  pb: 2,
  marginBottom: "20px",
  width: isMobile ? "auto" : "602px",
  flex: 1,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@media (max-width: 1000px)": {
    width: "auto"
  }
})

export const bottomInfoStyle = {
  backgroundColor: "transparent",
  color: "white",
  a: { color: "white" }
}

export const CircularProgressComponent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  filter: blur(0);
  z-index: 101;
`

export const SignupLeftSideWidgetContainer = styled.div<{
  bottomText?: boolean
  isMobile?: boolean
}>`
  width: ${(props) => !props.isMobile && "600px"};
  flex: 2;
  display: ${(props) => props.bottomText && "contents"};
  word-break: break-all;
`

export const WidgetLeftSide = styled.div<{
  isMobile?: boolean
}>`
  position: relative;
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  flex: initial;
  padding: ${(props) => (props.isMobile ? "15px" : "50px")};

  @media (max-width: 1000px) {
    width: ${(props) => !props.isMobile && "inherit"};
  }
`

export const WidgetRightSide = styled.div<{ isMobile?: boolean }>`
  position: relative;
  width: ${(props) => (props.isMobile ? "92%" : "calc(50% - 33px)")};
  display: ${(props) => !props.isMobile && "flex"};
  flex: ${(props) => !props.isMobile && "auto"};
  padding: ${(props) => !props.isMobile && "50px"};
  justify-content: ${(props) => !props.isMobile && "end"};
  margin-right: ${(props) => !props.isMobile && "-80px"};

  @media (max-width: 1400px) {
    width: ${(props) => !props.isMobile && "inherit"};
    margin-right: ${(props) => !props.isMobile && "0"};
    justify-content: ${(props) => !props.isMobile && "center"};
  }
`

export const WidgetContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: ${(props) => !props.isMobile && "100%"};

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`

export const DiagonalLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  height: 120%;
  background-color: #5f6b71;
  transform-origin: bottom left;
  transform: translateY(-10%) rotate(25deg);
  left: 40%;
  z-index: 1;

  @media (min-width: 768px) {
    left: calc(40% - 1px);
  }

  @media (max-width: 767px) {
    transform: translateY(-10%) rotate(12deg);
    left: calc(50% - 1px);
  }

  @media (max-width: 1630px) {
    transform: translateY(-10%) rotate(12deg);
    left: calc(45% - 1px);
  }

  @media (max-width: 1400px) {
    display: none;
  }
`

export const HeaderSection = styled.div`
  display: flex;
  background: ${darkBlueColor};
  justify-content: space-between;
  width: 100%;
  height: 110px;
  align-items: center;

  ${theme.breakpoints.down("md")} {
    display: none;
  }
`

export const HeaderSectionContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`

export const PageWelcomeContent = styled.div<{
  isSignupLoading: boolean
  isWidget?: boolean
  isMobile?: boolean
}>`
  display: flex;
  gap: ${({ isMobile }) => (isMobile ? "10px" : "20px")};
  justify-content: space-between;
  flex: 1;
  padding: ${(props) => (props.isMobile ? "20px" : "100px 85px 100px 85px")};
  border-radius: 20px;
  border: 1px solid #fff;
  width: 100%;
  position: relative;
  filter: ${(props) => (props.isSignupLoading ? "blur(4px)" : "")};
  min-height: 690px;
  overflow: hidden;

  @media (max-width: 900px) {
    height: auto;
    width: ${(props) => !props.isMobile && props.isWidget && "max-content"};
    padding: ${(props) => !props.isMobile && props.isWidget && "0"};
  }

  ${theme.breakpoints.down("md")} {
    flex-direction: column;
    & > div {
      width: 100%;
      max-width: 100%;
    }
  }
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55%;
`
export const LeftSideTop = styled.div<{
  isEnglish?: boolean
}>`
  width: ${(props) => (props.isEnglish ? "450px" : "auto")};
  @media (max-width: 1000px) {
    width: auto;
  }

  h2 {
    font-family: ${kanit.style.fontFamily};
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px;
    margin-bottom: 20px;
  }
  span {
    color: ${orangeColor};
    font-weight: 700;
  }
  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: #fff;
    a {
      color: #fff;
    }
  }

  ${theme.breakpoints.down("md")} {
    h2 {
      margin: 7px 0 5px 0;
      font-size: 34px;
      line-height: 36px;
    }
    p {
      font-size: 16px;
      margin-bottom: 10px;
      br {
        display: none;
      }
    }
  }
`
export const LeftSideBottom = styled.div`
  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color: #fff;
    span {
      font-weight: 700;
    }
  }
`

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 360px;

  button {
    background: transparent;
    border: 1px solid white;
    color: #fff;

    &:hover {
      svg {
        color: black;
      }
    }
  }

  .MuiFormControl-root {
    fieldset {
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    label,
    input {
      color: #fff;
    }
    input {
      padding: 14.5px;
    }
    button {
      border: 0;
    }
  }

  p,
  a {
    color: #fff;
    ${theme.breakpoints.up("md")} {
      text-align: center;
    }
  }

  ${theme.breakpoints.down("md")} {
    gap: 10px;
    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      color: #fff;
      span {
        font-weight: 700;
      }
    }
    button:last-of-type {
      margin: 10px 0 17px 0;
    }
  }
`

export const wordSeparatorStyle = {
  fontWeight: "700 !important",
  fontSize: "18px",
  color: `${orangeColor} !important`,
  textTransform: "uppercase",
  margin: 0
}

export const ImageContainer = styled.div`
  margin-left: -10px;
  @media (max-width: 1200px) {
    display: none;
  }
`
export const BackgroundImage = styled(Image)`
  height: 400px;
  width: fit-content;
  position: absolute;
  left: 0;
  bottom: 0px;
  ${theme.breakpoints.down("md")} {
    display: none;
  }
`

const BackgroundElementBase = styled.div`
  position: absolute;
  ${theme.breakpoints.down("md")} {
    display: none;
  }
`
export const BackgroundGradient = styled(BackgroundElementBase)`
  z-index: 0;
  position: absolute;
  width: 443px;
  height: 416px;
  background: radial-gradient(circle at 50% 50%, #4076a3 -200%, ${darkBlueColor} 70%);
  border-radius: 50%;
  bottom: -243px;
  left: 319px;
`
export const BackgroundGradientBig = styled(BackgroundElementBase)`
  width: 489px;
  height: 707px;
  background: radial-gradient(circle at 0% 50%, #4076a3 -100%, ${darkBlueColor} 75%);
  border-radius: 50%;
  bottom: 55%;
  left: -200px;
  transform: translateY(50%);
`
const darkTeal = "#2E6F61"
const lightTeal = "#54E3C0"
export const buttonStyle = {
  borderRadius: "100px",
  height: "45px",
  background:
    "linear-gradient(0deg, rgba(43, 121, 102, 1) 0%, rgba(73, 248, 204, 1) 100%, rgba(159, 214, 201, 1) 100%) !important",
  color: `${darkBlueColor} !important`,
  border: "0px !important",
  fontWeight: "400px",
  "&.Mui-disabled": {
    background: `linear-gradient(to top, ${darkTeal}, ${lightTeal})`,
    color: `${darkBlueColor} !important`
  },
  "&::after": {
    // eslint-disable-next-line @typescript-eslint/quotes, quotes
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    opacity: 0,
    transition: "opacity 0.3s",
    borderRadius: "85px"
  }

  // "&:hover::after": {
  //   opacity: 1
  // }
}
