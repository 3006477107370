import React, { useContext, useEffect, useMemo, useState } from "react"
import * as Sentry from "@sentry/react"
import * as prismicH from "@prismicio/helpers"
import "react-multi-carousel/lib/styles.css"
import { CircularProgress, Container, Typography } from "@mui/material"
import { Elements } from "prismic-reactjs"
import { Content } from "@prismicio/client"
import {
  ActionButton,
  ActionButtonActionType,
  AllFieldsType,
  AnimatedLogo,
  Field,
  FieldVariation,
  InfoBox,
  InfoBoxType,
  SliceNameType,
  SocialButtons,
  WordSeparator,
  WidgetProps,
  Prompt,
  RichText
} from "components"
import {
  BackgroundGradient,
  BackgroundGradientBig,
  BackgroundImage,
  CircularProgressComponent,
  DesktopContainer,
  HeaderSection,
  LeftSide,
  LeftSideTop,
  PageWelcomeContent,
  RightSide,
  buttonStyle,
  wordSeparatorStyle
} from "components/pages/signup/SignupWelcome.styled"
import {
  LoginPageType,
  ObjectWithFieldsType,
  fieldValidation,
  getFieldsAndInfoBoxes,
  goToPage,
  setToken,
  getRichText,
  sdkLogin,
  getIncompleteSignUpInfoAndUserData,
  setTokenExpiry,
  setCookieForPlatform
} from "utils"
import { useMobileOrTabletMode, useSignupData, useModal, useAccountData } from "hooks"
import { useLoginMutation } from "redux/features/loginapi/loginApi"
import { Context } from "pages/_app"
import { LoginType } from "redux/types"

type Props = {
  page: LoginPageType
  widgetprops?: WidgetProps
  validation: {
    messages: Content.ValidationmessagesDocument
  }
}

const Login = ({ page, widgetprops }: Props) => {
  const [localLoading, setLocalLoading] = useState(false)
  const isMobileOrTablet = useMobileOrTabletMode()
  const { isTradingViewConnectionPage } = widgetprops || {}
  const { openModal, displayModal } = useModal()
  const { slices, title, titleOrangeText, linkTextToSignup, wordSeparator } = page.data
  const { fieldsAndInfoBoxes, fields } = getFieldsAndInfoBoxes(slices)
  const [login] = useLoginMutation()
  const { updateSignupData } = useSignupData()
  const { clearAllData } = useAccountData()
  const [invalidData, setInvalidData] = useState(false)
  const [invalidStep, setInvalidStep] = useState(true)
  const { validationMessages } = useContext(Context)

  const [data, setData] = useState(
    fields.reduce((acc: ObjectWithFieldsType, field) => {
      return {
        ...acc,
        [field.fieldName]: ""
      }
    }, {})
  )

  useEffect(() => {
    clearAllData()
    Sentry.addBreadcrumb({
      category: "login",
      message: `User entered login`,
      level: "info"
    })
  }, [])

  const displaySocialButtons = () => {
    return <SocialButtons slices={slices} />
  }

  const displayFieldsAndInfoBoxes = () => {
    return fieldsAndInfoBoxes.map((fieldData: AllFieldsType | InfoBoxType) => {
      if (fieldData.sliceType === SliceNameType.InfoBox) {
        // Info Box
        return <InfoBox key={JSON.stringify(fieldData)} {...fieldData} />
      }

      // Field
      const handleChange = (newValues: ObjectWithFieldsType) => {
        setData({
          ...data,
          ...newValues
        })
      }

      // Add validation to password field
      const extraValidation = []
      if (fieldData.variation === FieldVariation.PasswordTextfield) {
        extraValidation.push({
          message: "Wrong email or password",
          condition: () => invalidData
        })
      }

      return (
        <Field
          key={fieldData?.fieldName}
          {...fieldData}
          currentValue={data[fieldData.fieldName]}
          handleChange={handleChange}
          signupData={{ login: data }}
          required={false}
          extraValidation={extraValidation}
          isLoginOrSignup
        />
      )
    })
  }

  useEffect(() => {
    const stepIsInvalid = () => {
      let isInvalid = false
      fields.forEach((field) => {
        const { fieldName } = field
        if (fieldValidation(field, data[fieldName], { login: data }, validationMessages, true, null).length > 0) {
          isInvalid = true
        }
      })
      return isInvalid
    }
    setInvalidStep(stepIsInvalid())
  }, [data, fields])

  const loginButton = useMemo(
    () =>
      slices.find(
        (slice) =>
          slice.slice_type === SliceNameType.ActionButton && slice.primary.actionType === ActionButtonActionType.Login
      ),
    [slices]
  )

  const forgottenPassword = useMemo(
    () =>
      slices.find(
        (slice) =>
          slice.slice_type === SliceNameType.ActionButton &&
          slice.primary.actionType === ActionButtonActionType.ForgotPassword
      ),
    [slices]
  )

  const openErrorModal = () => {
    openModal({
      title: getRichText([
        {
          text: "FAIL",
          type: Elements.heading2
        }
      ]),
      text: getRichText([
        {
          text: "Something went wrong - please try again later.",
          type: Elements.paragraph
        }
      ])
    })
  }

  const displayLoginButton = () => {
    if (loginButton) {
      const onClick = async () => {
        if (!invalidStep) {
          Sentry.setUser({ email: data.username })
          setLocalLoading(true)
          try {
            const result = (await login({
              body: {
                username: data.username,
                password: data.password
              }
            }).unwrap()) as LoginType
            if (result.access_token) {
              // store cookie for TN trader platform to login
              // to auth0 - DEV-4536
              setCookieForPlatform(result)
              setToken(result)
              setTokenExpiry(result)
              const { gotRedirected } = await getIncompleteSignUpInfoAndUserData(
                openErrorModal,
                validationMessages,
                updateSignupData
              )
              if (!gotRedirected) {
                if (isTradingViewConnectionPage) {
                  sdkLogin(data, () => {
                    openErrorModal()
                  })
                  return
                } else {
                  goToPage(loginButton.primary.defaultRedirect)
                }
              }
            } else {
              Sentry.captureException(new Error("Login error:" + result?.error), { tags: { category: "login" } })
              setLocalLoading(false)
              setInvalidData(true)
            }
          } catch (error) {
            Sentry.captureException(error)
            setLocalLoading(false)
            openErrorModal()
          }
        }
      }

      return (
        <ActionButton
          id="login"
          displayText={loginButton.primary.displayText}
          onClick={onClick}
          variant={loginButton.primary.variant}
          disabled={invalidStep}
          actionType={ActionButtonActionType.Login}
          sx={buttonStyle}
          isSignupOrLogin
        />
      )
    }
    return null
  }

  const onForgotPasswordClick = () => {
    goToPage("/login/forgot-password")
  }

  return (
    <>
      <HeaderSection>
        <Container maxWidth="xl">
          <AnimatedLogo width={250} height={60} />
        </Container>
      </HeaderSection>
      <DesktopContainer isMobile={isMobileOrTablet}>
        <BackgroundGradient />
        <BackgroundGradientBig />
        <Container maxWidth="xl">
          <PageWelcomeContent isSignupLoading={localLoading} isMobile={isMobileOrTablet}>
            <BackgroundImage
              height="400"
              width="400"
              alt={"banner"}
              src={
                "https://images.prismic.io/tnfev2/ZmBNJ5m069VX1ecj_backgroundimage.png?auto=format,compress?auto=compress,format"
              }
            />
            <LeftSide>
              <LeftSideTop>
                <Typography variant="h2" color="white" sx={{ fontWeight: "700 !important" }}>
                  <span>{titleOrangeText} </span>
                  {prismicH.asText(title)}
                </Typography>
                <RichText field={linkTextToSignup} id="loginsignup" />
              </LeftSideTop>
            </LeftSide>
            {localLoading && (
              <CircularProgressComponent>
                <CircularProgress />
              </CircularProgressComponent>
            )}
            <RightSide>
              {displayFieldsAndInfoBoxes()}
              <Typography
                id="loginforgotpassword"
                variant="body1"
                onClick={onForgotPasswordClick}
                sx={{
                  alignSelf: "flex-start",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#fff"
                }}
              >
                {forgottenPassword.primary.displayText}
              </Typography>
              {displayLoginButton()}
              <WordSeparator text={wordSeparator} lineColor={"#fff"} sx={wordSeparatorStyle} />
              {displaySocialButtons()}
              <span style={{ marginTop: 10 }} />
            </RightSide>

            {displayModal()}
          </PageWelcomeContent>
        </Container>
        <Prompt />
      </DesktopContainer>
    </>
  )
}

export default Login
